.custom-handle{
    width: 10px;
    height: 10px;
    border: 1px solid rgb(0, 0, 0);
    background: #b1b4be;
    cursor: pointer;
}


::marker{
    color:#7687A3;
}